<template>
  <div>
    <v-overlay :value="loading" absolute opacity="0">
      <v-progress-circular color="primary" indeterminate />
    </v-overlay>

    <v-container v-if="deposit && !loading" class="deposit-container pb-0 pb-sm-3">
      <app-link-back-to-deposits :text="$t('Back to Deposits')" :to="backRoute" />
      <h1 class="mb-10">
        {{ $t('DepositTypeTitle', {title: depositType.title}) }}
      </h1>

      <v-row class="mx-n3 mx-sm-0" no-gutters>
        <v-col>
          <v-card
            :ripple="false"
            class="px-3 px-sm-6 py-sm-3 px-md-12 py-md-6"
            flat
          >
            <v-row>
              <v-col>
                <div class="text-caption text--secondary">
                  {{ $t('Title') }}
                </div>
                <p class="mb-5 text-body-2 font-weight-bold">
                  {{ deposit.name }}
                </p>

                <div class="text-caption text--secondary">
                  {{ $t('Project') }}
                </div>
                <p class="mb-5 text-body-2">
                  {{ deposit.project ? deposit.project.name : '' }}
                </p>

                <template v-if="deposit.description">
                  <div class="text-caption text--secondary">
                    {{ $t('Description') }}
                  </div>
                  <p class="mb-5 text-body-2">
                    {{ deposit.description }}
                  </p>
                </template>

                <template v-if="deposit.tags && deposit.tags.length">
                  <div class="text-caption text--secondary">
                    {{ $t('Tags') }}
                  </div>
                  <div class="mb-5">
                    <v-chip
                      v-for="(tag, i) in deposit.tags"
                      :key="`tag-${i}`"
                      class="deposit-tag"
                      color="background"
                      label
                      small
                    >
                      {{ tag }}
                    </v-chip>
                  </div>
                </template>

                <template v-if="commitHash">
                  <div class="text-caption text--secondary">
                    {{ $t('Commit hash') }}
                  </div>
                  <p class="mb-5 text-body-2">
                    {{ commitHash }}
                  </p>
                </template>
              </v-col>
              <v-col cols="12" sm="auto">
                <div class="text-center">
                  <v-btn
                    v-if="deposit.status === 'locked'"
                    :loading="registering"
                    :ripple="false"
                    block
                    class="mb-3"
                    color="primary"
                    rounded
                    @click="register"
                  >
                    {{ $t('Register') }}
                  </v-btn>
                  <v-btn
                    v-if="deposit.status === 'in_progress'"
                    :ripple="false"
                    class="button-in-progress mb-3"
                    color="primary"
                    data-cy="button-in-progress"
                    outlined
                    rounded
                  >
                    {{ $t('In progress') }}
                  </v-btn>
                </div>
                <div v-if="['in_progress', 'registered'].includes(deposit.status)">
                  <div v-if="ipchainUrl" class="text-center">
                    <vue-qrcode :options="qrcodeOptions" :value="ipchainUrl" />
                    <deposit-link-ipchain
                      :deposit="deposit"
                      class="d-block"
                      small
                    />
                  </div>
                  <deposit-link-certificate
                    v-if="deposit.ipchain && deposit.ipchain.certificate_link"
                    :deposit="deposit"
                    class="mt-2 text-center"
                    small
                  />
                </div>
              </v-col>
            </v-row>

            <!-- Deposit content -->
            <v-row>
              <v-col>
                <div class="text-caption text--secondary">
                  {{ $t('Deposit content') }}
                </div>
                <div
                  v-if="deposit.type === 'design'"
                  class="deposit-content--design-preview"
                >
                  <img
                    v-if="deposit.type === 'design'"
                    :src="deposit.preview"
                    alt="Deposit preview"
                  >
                </div>
                <pre
                  v-if="deposit.type === 'implementation'"
                  class="deposit-content--code-no-lang"
                >{{ deposit.content }}</pre>
                <div v-if="deposit.type === 'project'">
                  <deposit-files-list
                    v-if="deposit.content"
                    :files="projectFiles"
                  />
                  <a v-else :href="deposit.files[0].url">
                    Download deposit content
                  </a>
                </div>
                <div v-if="deposit.type === 'screens'">
                  <v-row align="stretch">
                    <v-col
                      v-for="(image, i) in deposit.files"
                      :key="`screen-${i}`"
                      xl="2"
                      lg="3"
                      md="4"
                      sm="6"
                      cols="12"
                    >
                      <image-preview
                        :dimensions="`${image.width}×${image.height}`"
                        :filename="image.filename"
                        :href="image.url"
                        :size="Number(image.size)"
                        :src="image.url"
                        target="_blank"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>

            <!-- Authors and rightsholders -->
            <v-row>
              <v-col cols="auto" lg="6">
                <div class="text-caption text--secondary">
                  {{ $t('Authors') }}
                </div>
                <v-simple-table class="actors-list">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>{{ $t('Author') }}</th>
                        <th>{{ $t('Rights') }}</th>
                        <th>{{ $t('Date') }}</th>
                        <th>{{ $t('Territories') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(actor, i) in authors" :key="`au-${i}`">
                        <td>{{ actor.name }}</td>
                        <td>{{ contributionWeightFormat(actor) }}</td>
                        <td class="text-no-wrap">
                          <app-date :value="actor.dateFrom" />
                        </td>
                        <td>{{ territoriesList(actor) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col v-if="rightsholders.length" cols="auto" lg="6">
                <div class="text-caption text--secondary">
                  {{ $t('Rightsholders') }}
                </div>
                <v-simple-table class="actors-list">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>{{ $t('Rightsholder') }}</th>
                        <th>{{ $t('Rights') }}</th>
                        <th>{{ $t('Date') }}</th>
                        <th>{{ $t('Territories') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(actor, i) in rightsholders" :key="`rh-${i}`">
                        <td>{{ actor.name }}</td>
                        <td>{{ contributionWeightFormat(actor) }}</td>
                        <td class="text-no-wrap">
                          <app-date :value="actor.dateFrom" />
                        </td>
                        <td>{{ territoriesList(actor) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import {sections} from '@/store/const-deposit-statuses'
import AppDate from '@/components/AppDate'
import AppLinkBackToDeposits from '@/components/AppLinkBackToDeposits'
import DepositFilesList from '@/components/DepositFilesList'
import DepositLinkCertificate from '@/components/DepositLinkCertificate'
import DepositLinkIpchain from '@/components/DepositLinkIpchain'
import ImagePreview from '@/components/ImagePreview'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'DepositDetails',
  components: {
    AppDate,
    AppLinkBackToDeposits,
    DepositFilesList,
    DepositLinkCertificate,
    DepositLinkIpchain,
    ImagePreview,
    VueQrcode,
  },
  mixins: [error401handler],
  props: {
    projectId: {type: [Number, String], required: true},
    depositId: {type: [Number, String], required: true}
  },
  data () {
    return {
      loading: false,
      registering: false,
      qrcodeOptions: {
        color: {
          dark: this.$vuetify.theme.themes.light.secondary.base,
          light: this.$vuetify.theme.themes.light.background.base
        },
        margin: 4,
        width: 160
      },
      projectFiles: []
    }
  },
  computed: {
    ...mapState({
      isBusinessAccount: state => state.profile.accountType === 'business',
      profileLoaded: state => state.loaders.profile.status,
      locale: state => state.locale,
      depositTypes: state => state.config.deposit?.types,
      languages: state => state.config.deposit?.types?.implementation?.languages,
      territories: state => state.config.deposit?.actors?.territories || {},

      currentPagePrv: state => state.deposits.pagination.currentPage,
      currentPageBiz: state => state.depositsBusiness.pagination.currentPage,
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    currentPage () {
      return this.isBusinessAccount ? this.currentPageBiz : this.currentPagePrv
    },
    ...mapGetters({
      getDepositPrvById: 'deposits/getById',
      getDepositBizById: 'depositsBusiness/getById',
      editablePrv: 'deposits/editable',
      editableBiz: 'depositsBusiness/editable',
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    getDepositById () {
      return this.isBusinessAccount ? this.getDepositBizById : this.getDepositPrvById
    },
    editable () {
      return this.isBusinessAccount ? this.editableBiz : this.editablePrv
    },

    deposit () {
      return this.getDepositById(this.depositId)
    },
    depositType () {
      return this.depositTypes[this.deposit.type]
    },
    commitHash () {
      if (!this.deposit.files) return false
      return this.deposit.files[0]?.meta?.commitHash
    },
    ipchainUrl () {
      return this.deposit.ipchain?.IPChainLink || ''
    },
    backRoute () {
      let section = sections.find(s => {
        return s.backFromRoutes.includes(this.$route.name)
      })

      let route = {
        name: 'deposits',
        params: {
          projectId: this.projectId,
          sectionId: section.id
        }
      }
      if (this.currentPage > 1) {
        route.query = {page: this.currentPage}
      }
      return route
    },
    authors () {
      return this.deposit.actors.filter(a => a.rights === 'author')
    },
    rightsholders () {
      return this.deposit.actors.filter(a => a.rights === 'rightholder' || a.rights === 'rightsholder')
    },
  },
  watch: {
    profileLoaded (val) {
      if (val) this.initialLoad()
    }
  },
  mounted () {
    if (this.profileLoaded) this.initialLoad()
  },
  methods: {
    async initialLoad () {
      this.loading = true
      if (!this.deposit) {
        try {
          await this.load(this.depositId)
        } catch (e) {
          this.handleError(e)
        }
      }
      if (this.editable(this.depositId)) {
        return this.$router.replace({
          name: 'deposit-edit',
          params: {
            projectId: this.projectId,
            depositId: this.depositId
          }
        })
      }
      if (this.deposit.type === 'project') {
        try {
          let content = JSON.parse(this.deposit.content)
          if (Array.isArray(content)) {
            this.projectFiles = content
          } else if (typeof content === 'object') {
            this.projectFiles = [content]
          }
        } catch (e) {
          console.log(e)
        }
      }
      this.loading = false
    },
    ...mapMutations({
      showNotify: 'notify/show',
    }),
    ...mapActions({
      loadPrv: 'deposits/dpLoadOne',
      loadBiz: 'depositsBusiness/dpLoadOne',
      registerDepositPrv: 'deposits/dpRegister',
      registerDepositBiz: 'depositsBusiness/dpRegister',
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    load () {
      return (this.isBusinessAccount ? this.loadBiz : this.loadPrv)(...arguments)
    },
    registerDeposit () {
      return (this.isBusinessAccount ? this.registerDepositBiz : this.registerDepositPrv)(...arguments)
    },

    contributionWeightFormat (author) {
      return author.contributionWeight.includes('/')
        ? author.contributionWeight
        : `${Math.floor(author.contributionWeight * 100)}%`
    },
    territoriesList (actor) {
      return actor.territory.map(t => this.territories[t]).join(', ')
    },
    async register () {
      this.registering = true
      try {
        await this.registerDeposit(this.deposit.id)
        this.showNotify({
          text: this.deposit.status === 'registered'
            ? this.$t('Deposit registered')
            : this.$t('Registration of deposit started'),
          color: 'success'
        })
      } catch (e) {
        this.handleError(e, {
          mode: 'modal',
          title: this.$t('Unable register deposit')
        })
      }
      this.registering = false
    }
  }
}
</script>

<style lang="scss" scoped>
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    border: thin solid var(--v-outline-base);
    border-radius: $border-radius-small;
    padding: $spacer * 2 $spacer * 4;
  }
</style>
