<template>
  <v-treeview
    :items="files"
    dense
    item-children="content"
    item-text="name"
    open-on-click
    transition
  >
    <template v-slot:prepend="{item, open}">
      <v-icon v-if="item.type !== 'file'">
        {{ open ? icons.folderOpen : icons.folder }}
      </v-icon>
      <v-icon v-else>
        {{ icons[item.type] }}
      </v-icon>
    </template>
    <template v-slot:label="{item, open}">
      {{ item.name }}
      <span v-if="item.type === 'file'" class="grey--text ml-2">
        {{ humanize(item.size) }}
      </span>
    </template>
  </v-treeview>
</template>

<script>
import {mdiFileDocumentOutline, mdiFolder, mdiFolderOpen} from '@mdi/js'
import humanizeSizeMixin from '@/mixins/humanize-size'

export default {
  name: 'DepositFilesList',
  mixins: [humanizeSizeMixin],
  props: {
    files: {type: Array, required: true}
  },
  data () {
    return {
      icons: {
        folder: mdiFolder,
        folderOpen: mdiFolderOpen,
        file: mdiFileDocumentOutline
      }
    }
  }
}
</script>
